// Beta
export const beta: OgEnvironment = {
  env: 'beta',
  baseUrl: 'https://beta.app.onegoalfinance.com',
  segmentKey: 'IcDllW276f9VpV6nm7RQvTHAV7k6lTm3',
  firebaseConfig: {
    apiKey: 'AIzaSyAvHuCTcKUUFqNAQLrS7XMio4hoq-Da9j0',
    authDomain: 'one-goal-app.firebaseapp.com',
    projectId: 'one-goal-app',
    storageBucket: 'one-goal-app.appspot.com',
    messagingSenderId: '682552540606',
    appId: '1:682552540606:web:9014d33a2f55846c',
    measurementId: 'G-23W8GDSXRZ',
  },
  stripeKey: 'pk_live_lYcVnPVoWb2wFdBz129nWDfu',
  logRocket: {
    appId: 'uslch6/one-goal-beta',
    assetUrl: 'https://beta.app.onegoalfinance.com/',
  },
  intercom: {
    appId: 'v8u5kaoe',
  },
};
