// Local Development with Emulator
export const local: OgEnvironment = {
  env: 'dev',
  baseUrl: 'localhost:3333',
  segmentKey: 'Y19HeKAkYKFiJ2T5LuuZRQcxxxCw3npx',
  firebaseConfig: {
    apiKey: 'AIzaSyDP72xYo-NqIhgPcV1nLrrcum4KUmFwUdM',
    authDomain: 'one-goal-development.firebaseapp.com',
    projectId: 'one-goal-development',
    storageBucket: 'one-goal-development.appspot.com',
    messagingSenderId: '920849602790',
    appId: '1:920849602790:web:d74aa7f4b61ba70af34327',
    measurementId: 'G-L4XSZ0KSMB',
  },
  stripeKey: 'pk_test_ikx1ZReHFZdWGOJENU6d9s9c',
  logRocket: {
    appId: null,
    assetUrl: 'https://development.app.onegoalfinance.com/',
  },
  intercom: {
    appId: 'dr4loh9x',
  },
};
