import { dev } from './development';
import { beta } from './beta';
import { prod } from './production';
import { local } from './local';
import { Env } from '@stencil/core';
import { Env as OgEnv } from '@og-shared/types';

const environments: { [key in OgEnv]: OgEnvironment } = {
  beta,
  dev,
  prod,
  local,
};
export const environment: OgEnvironment =
  environments[(Env?.environment as OgEnvironment['env']) || 'dev'];
